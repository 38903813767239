<template>
<keep-alive>
  <ion-item>
      <ion-label>{{item.item}}</ion-label>
      <ion-label>{{item.description}}</ion-label>
      <ion-button @click="viewDatail(item.id)" color="light">Ver Detalle</ion-button>
  </ion-item>
</keep-alive>
</template>
<script>
import {IonItem, IonLabel, IonButton} from '@ionic/vue';
import { mapActions } from 'vuex';
export default {
    name: 'item-list-item',
    components: {
        IonItem,
        IonLabel,
        IonButton
    },
    props:['item'],
    methods:{
        ...mapActions('actividad',['retrieveActividades']),
        ...mapActions('item',['setItemId']),
        async viewDatail(id){
            await this.retrieveActividades(this.item.id);
            await this.setItemId(id);
            this.$router.push('/item/'+ this.item.item)
        }
        }

}
</script>

<style>

</style>